import React, { useState } from 'react';
import { DocumentsWrapper } from '../Investitors/Investors.style';
import { DocumentsContainer } from '../../components/DocumentsContent/DocumentContent.style';
import { Field, Form, Formik } from 'formik';
import { FormCheckbox, FormGroup, FormLabel, PrimaryButton } from '../../shared-styled-components/Forms.style';
import { useMutation } from 'react-query';
import { setNewsLetter} from '../../services/newsletter.service';
import { useUser } from '../../services/user.service';
import DocumentsSideBar from '../../components/DocumentsContent/DocumentsSideBar';
import { Helmet } from 'react-helmet-async';

const Profile = () => {
  const [unsubscribeMessage, setUnsubscribeMessage] = useState('');

  const {data: user} = useUser();

  const initialValues = {
    email:  user?.data.data.email || '',
    name: user?.data.data.first_name || '',
    surname: user?.data.data.last_name || '',
    company: user?.data.data.title || '',
    email_notifications:user?.data.data.email_notifications || false,
  };

  const newsLetterMutation = useMutation(
    (credentials:{email_notifications:boolean}) => {
      return setNewsLetter(
        credentials.email_notifications
      );
    });

  const handleSubmit = (values:any,actions:any) => {
    const emailNotification = values.email_notifications;

    setNewsLetter(false)
      .then(() => {
        if(emailNotification){
          setUnsubscribeMessage(`
            You have been successfully subscribed to our newsletter, you will receive our e-mails`);
        } else {

          setUnsubscribeMessage(`
          You have been successfully unsubscribed from our newsletter, you will no longer receive our e-mails
          `);
        }
      })
      .catch(() => {
        setUnsubscribeMessage('Something went wrong');
      });
    newsLetterMutation.mutate(values);
  };

  return (
    <DocumentsWrapper>
      <Helmet>
        <title>Profile, privacy and user data | EOLO Investor</title>
        <meta name="description" content="Here you can check and modify your privacy data and
        terms & conditions. Find out more on the official website" />
      </Helmet>
      <DocumentsSideBar />
      <DocumentsContainer>
        <h4>Profile</h4>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={(values, actions) => handleSubmit(values,actions)}
          >
            {({ errors, touched }) => {

              return(
                <Form>
                  <FormGroup>
                    <FormLabel htmlFor="email">E-mail</FormLabel>
                    <Field disabled={true} type="email" id="email" name="email" />
                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Field disabled={true} type="text" id="name" name="name" />

                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="surname">Surname</FormLabel>
                    <Field disabled={true} type="text" id="surname" name="surname" />

                  </FormGroup>
                  <FormGroup>
                    <FormLabel htmlFor="company">Company</FormLabel>
                    <Field disabled={true} type="text" id="company" name="company" />
                  </FormGroup>
                  <FormGroup>
                    <FormCheckbox>
                      <Field type="checkbox" id="email_notifications" name="email_notifications"/>
                      <FormLabel htmlFor="email_notifications">
                      I would like to receive newsletter</FormLabel>
                    </FormCheckbox>
                  </FormGroup>
                  <PrimaryButton disabled={newsLetterMutation.isLoading} type="submit">Submit</PrimaryButton>
                  <p>{unsubscribeMessage}</p>
                </Form>
              );
            }}
          </Formik>
        </div>
      </DocumentsContainer>
    </DocumentsWrapper>
  );
};

export default Profile;

