import React from 'react';
import PrivateLayout from '../../components/PrivateLayout';
import Layout from '../../components/PublicLayout';
import { PrivacySubtitle, PrivacyTitle } from '../../components/TermsAndConditionsContent/Privacy.style';
import Privacy_Policy from '../../components/TermsAndConditionsContent/Privacy_Policy';
import { getLocalAccessToken } from '../../services/token.service';
import { FormsPage } from '../../shared-styled-components/Forms.style';

const PrivacyPolicy = () => {
  const token = getLocalAccessToken();

  return(
    <div className="privacyPolicy">
      {token ?
        <PrivateLayout classNames="layoutRow">
          <FormsPage>
            <PrivacyTitle>Privacy policy – Investor relation website</PrivacyTitle>
            <PrivacySubtitle>
              Privacy policy pursuant to articles 13-14 of UE Regulation 679/2016 (General Data Protection Regulation)
            </PrivacySubtitle>
            <Privacy_Policy />
          </FormsPage>
        </PrivateLayout>
        :
        <Layout classNames="layoutRow">
          <FormsPage>
            <PrivacyTitle>Privacy policy – Investor relation website</PrivacyTitle>
            <PrivacySubtitle>
              Privacy policy pursuant to articles 13-14 of UE Regulation 679/2016 (General Data Protection Regulation)
            </PrivacySubtitle>
            <Privacy_Policy />
          </FormsPage>
        </Layout>
      }
    </div>
  );
};

export default PrivacyPolicy;
