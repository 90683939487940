import React from 'react';
import { FormBodyWrapper, FormWrapper } from '../../shared-styled-components/Forms.style';
import { PrivacyWrapper } from './Privacy.style';
import {Helmet} from 'react-helmet-async';

const Privacy_Policy = () => {
  return (
    <FormBodyWrapper>
      <Helmet>
        <title>Privacy Policy | EOLO</title>
        <meta name="description" content="Consult the EOLO Privacy policy, everything you need to know about
        data collection, data subject rights and much more." />
      </Helmet>
      <PrivacyWrapper>
        <div>
          <h4>Foreword</h4>
          <p>Pursuant to EU Regulation 679/2016 - General Data Protection Regulation ("GDPR")
           - the Italian Privacy Code (Legislative Decree 196/2003) as amended by Legislative
           Decree 101/2018 and other applicable national legislation (hereinafter “GDPR”,
           “Privacy Code” and other applicable national legislation are collectively referred to
           as "Applicable Legislation"), EOLO SpA recognizes the importance of the protection of personal
           data and considers their protection one of the main objectives of its business.</p>
          <p>Before communicating any personal data, EOLO SpA invites you to read this privacy policy carefully,
            as it contains important information on the protection of your personal data and on the security
            measures adopted.</p>
          <p>EOLO SpA informs you that the processing of your personal data, in accordance with the legal
            provisions of the Applicable Legislation and the obligations of confidentiality, will be based
            on the principles of lawfulness, correctness, transparency, limitation of purposes and
            conservation, minimization of data, integrity and confidentiality.</p>

          <h4>Data Controller and Data Protection Officer</h4>
          <p>The Data Controller is EOLO SpA with registered office in Via Gran San Bernardo,
            12 - 21052 - Busto Arsizio (VA), (hereinafter, "EOLO" or "Data Controller"),
            which can be contacted at the address: privacy@eolo.it.<br/>
            EOLO has appointed a Data Protection Officer (DPO), available at the address: dpo@eolo.it
            for any information concerning the processing of personal data carried out by the Controller.
          </p>

          <h4>Categories of processed personal data</h4>
          <p>The types of information we collect about you when you visit and interact with this website are:<br/><br/>
          Your contact details such as name and email address where you have registered through the website
          www.investors.eolo.it; or <br/>
          If you have agreed to cookies: data such as data sources and number of page views and content usage<br/>
          EOLO will process this personal data in compliance with the Applicable Legislation, assuming that it
          refers to the contracting party or to third parties who have expressly authorized it to provide such
          data. With respect to this hypothesis, the contracting party assumes all of the obligations and
          responsibilities of law, holding EOLO harmless with respect to any dispute, claim, request for
          compensation for damages, etc. that may be received from third parties whose personal data has been
          conferred in violation of the Applicable Legislation.
          </p>
          <h4>Purpose, legal basis and nature of processing</h4>
          <p>1.	The personal data referred to in letter a) shall be processed by EOLO for the establishment
            or execution of the contractual relationship related to the use of the website on the basis of
            the condition of lawfulness pursuant to Articles 6.1 letter b), c) of the GDPR. </p>
          <p>2. The processing of your personal data as per letter a) will also be aimed at sending you
              communications with financial content, i.e. to send you newsletters. For this purpose,
              the processing is legally based on your specific consent, which you are free to give
              or not and which can be revoked at any time.</p>
          <p>3.	The personal data referred to in letter b) will be processed only after separate and
          specific consents have been given, which are optional and revocable at any time, pursuant
          to Article 6.1 letter a) of the GDPR, in relation, for example, to the purpose of online
          advertising, through the use of cookies and other similar technologies in order to improve
          the user experience and enable personalized features and content (further information is
          available in the relevant notice available at  www.investors.eolo.it/cookie-policy</p>
          <h4>Provision of personal data and consequences related to non-provision of such data</h4>
          <p>The provision of personal data for the purposes referred to in point 1 is optional, but necessary
            for the fulfilment of contractual obligations. In this case, failure to provide personal data will
            make it impossible for the Investor relations Manager  to share financial documentation with you.
          <br/>
        The provision of personal data for the purposes set out in point 2 is optional, and failure to provide such data
        will make it impossible for the Investor relations Manager  to carry out the activities required to achieve such
        purposes.
          </p>
          <p>The provision of personal data for the purposes set out in point 3 is optional, and failure to
            provide such data will make it impossible for the Investor relations Manager to carry
             out the activities required to achieve such purposes.</p>
          <h4>Recipient of personal data</h4>
          <p>
          Your personal data may be processed by the employees of EOLO's corporate functions in charge of pursuing
          the above-mentioned purposes, who have been expressly authorized to process them and who have received
          adequate operating instructions.
            <br/>Your personal data may also be transmitted to external subjects designated by EOLO as data
          processors who have been given adequate operating instructions. These subjects provide EOLO with
          services instrumental to the purposes indicated above and are, by way of example, included in the following
          categories
            <br/>a. companies that provide EOLO with services for information systems management:
            <br/>b. other parties who are granted access to the same by Applicable law
          </p>
          <h4>Extra UE transfer of personal data</h4>
          <p>Some of the personal data provided by the data subject may be transferred to recipients located
            outside the European Economic Area. EOLO assures you that in this case the electronic and paper
            processing of personal data by the recipients will take place in compliance with the Applicable
            Legislation. In such cases, the transfers are based alternatively on an adequacy decision
            regarding the protection of personal data, on the Standard Contractual Clauses approved by
            the European Commission. The updated list of third countries to which EOLO may transfer personal
            data is always available on request.</p>
          <h4>Retention of personal data</h4>
          <p>Personal data will be stored for the time prescribed by the Applicable Legislation.
              EOLO will retain your personal data for a period of time that does not exceed the
              achievement of the purposes for which it was collected or subsequently processed, as well
              as for the period of time required by law for administrative purposes, the management of any
              complaints, disputes or criminal proceedings:
          <br/>
              i.	The personal data processed will be kept by EOLO only for the time necessary, in particular:<br/>
              - the data processed for the establishment or execution of the contractual relationship will be kept for
              the entire duration of the contract and subsequently deleted, unless the need for further storage arises
              to allow EOLO to ascertain, exercise, defend its rights in court. In this case, the personal data will
               be kept for the entire duration of the judicial dispute, until the time limit for appeals has expired.
          <br/>
               -data relating to the sending of the newsletter and, more generally, the website logs will be kept
               for a maximum of 13 months.
          <br/>
               After the aforementioned retention periods have expired, personal data will be destroyed,
               erased or made anonymous, compatible with the technical procedures of erasure and backup.
          </p>
          <h4>Data subjects rights</h4>
          <p>Within the limits of the Applicable Legislation, the data subject has the right to request from EOLO,
            at any time:<br/>
            - access to the personal data concerning him/her, their cancellation, the rectification of
            incorrect data, the integration of incomplete data, as well as the limitation of processing;<br/>
            - where the processing is based on consent or contract and is carried out by automated means,
            to receive in a structured, commonly used and machine-readable format your personal data, as well
            as, if technically feasible, to transmit them to another controller without hindrance
            ("right to data portability");
          <br/>
          - to object to processing based on the legitimate interest of the Controller and/or to object,
          without limitation, to processing for direct marketing purposes;<br/>
          - to withdraw the consent given at any time. Requests should be sent by e-mail to: privacy@eolo.it.<br/>
          With reference to the processing referred to in point 2, we would like to remind you that the consent
          given may be revoked at any time, even after the contractual relationship has ended, via the specific
          section in your reserved area on the www.eolo.it website or by writing to privacy@eolo.it. <br/>
          Pursuant to the Applicable Legislation, the data subject has in any case the right to lodge a complaint
          with the competent Control Authority (art. 77 of the GDPR), as well as to take legal action
          (art. 79 of the GDPR).
          </p>
          <h4>Modifications</h4>
          <p>EOLO reserves the right to modify or simply update the content of this privacy policy, in part or
            in full, also due to changes in the Applicable Legislation. The amendments shall be binding as
            soon as they are published on the website www.eolo.it. EOLO therefore invites the interested
            parties to regularly visit this section in order to be aware of the most recent and updated
            version of the informative note, and thus to be updated on the methods and purposes of the
            processing of personal data carried out by the Controller.
          </p>

        </div>

      </PrivacyWrapper>

    </FormBodyWrapper>
  );
};

export default Privacy_Policy;
