import styled from 'styled-components';
import bg from '../../assets/images/img-professionista_2x-min.webp';

export const HeroConfirmPasswImages = styled.div `
  background-image: 
  url(${bg});
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin:15px;
  width:100%;
  height:500px;
  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const EoloRobot = styled.img `
  width:100px;
`;

export const ConfirmPasswWrapper = styled.div `
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding: 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p{
    color: #656b73;
    margin: 0;
    text-align: center;
  }
  @media screen and (max-width: 768px){
   max-width: 100%;
   margin-bottom: 30px;
   padding: 0 10px;
  }
`;

export const EmailWrapper = styled.div `
  display: flex;
  width: 100%;
  /* justify-content: flex-start; */
  align-items: center;
  margin: 20px 0 30px;
  a{
    color: #656b73;
    padding: 0 15px 0 0;
    text-decoration: none;
    @media screen and (max-width: 560px){
      padding: 0 10px;
    }
  }
  img{
    width: 25px;
    margin: auto;
    vertical-align: middle;
  }
`;

