import React, { ReactChild, useEffect, useState} from 'react';
import Footer from './Footer/Footer';
import { HamburgerIcon, HamburgerMenu } from './Header/Header.style';
import PrivateHeader from './Header/PrivateHeader';
import { useNavigate,useLocation } from 'react-router';
import { logout } from '../services/auth.service';
import { MobileMenu, PrimaryButton, ProfileLink } from '../shared-styled-components/Forms.style';
import { Link } from 'react-router-dom';
import iconX from '../assets/images/iconX.png';
import userWhite from '../assets/images/user-white.png';

type LayoutProps = {
  classNames:string
  children:ReactChild
}

const PrivateLayout = ({classNames, children}:LayoutProps) => {

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  const navigate = useNavigate();

  const handleLogout =() => {
    logout();
    navigate('/');
  };

  return (
    <div className="main">
      <div id="mobile" className={`${hamburgerOpen ? 'navigation': ''}`}>
        <div id="sidebar">

          <HamburgerMenu onClick={toggleHamburger}>
            <HamburgerIcon alt="menu button"
              src={iconX}/>
          </HamburgerMenu>
          <MobileMenu>
            <Link to="/homepage" onClick={toggleHamburger}>Homepage</Link>
            <Link to="/documents/annual_report" onClick={toggleHamburger}>Annual Reports</Link>
            <Link to="/documents/quarterly_reporting" onClick={toggleHamburger}>Quarterly Reports</Link>
            <Link to="/documents/presentations" onClick={toggleHamburger}>Presentations</Link>
            <Link to="/documents/ratings" onClick={toggleHamburger}>Ratings</Link>
            <Link to="/news" onClick={toggleHamburger}>News</Link>
            <Link to="/documents/contacts" onClick={toggleHamburger}>Contacts</Link>
            <ProfileLink to="/profile" onClick={toggleHamburger}>
              <img src={userWhite} alt="profile icon"/> Profile
            </ProfileLink>
          </MobileMenu>
          <PrimaryButton onClick={handleLogout}>
            Log out
          </PrimaryButton>
        </div>

        <div className={`${hamburgerOpen ? 'eoloContainerScale' : ''} eoloContainer`}>

          <PrivateHeader toggleHamburger={toggleHamburger} hamburgerOpen={hamburgerOpen}/>
          <div className={classNames}>
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default PrivateLayout;
