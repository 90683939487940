import {useQuery} from 'react-query';
import api from './api';

export const getAllNews = (page:string='1', limit:number=2) => {
  const published = 'published';

  return api.get(
    `items/news?page=${page}&limit=${limit}&filter={"status":{"_eq": "${published}"}}&sort[]=-date_created`
  );
};

export const useNews = (page?:string, limit?:number) => {
  return useQuery(['News',{page, limit}], () => getAllNews(page, limit));
};

export const getLimitNews = () => {
  const published = 'published';

  return api.get(`items/news?limit=3&filter={"status":{"_eq": "${published}"}}&sort[]=-date_created`);
};

export const useLimitNews = () => {
  return useQuery(['News'], () => getLimitNews());
};


export const getNewsById = (id:string) => {
  return api.get(`items/news/${id}`);
};

export const useNewsDetails = (id:string) => {
  return useQuery(['News',{id}], () => getNewsById(id));
};

export const getNewsCount = () => {
  return api.get('items/news?aggregate[count]=title');
};

export const useNewsCount = () => {
  return useQuery(['NewsCount'], () => getNewsCount());
};
