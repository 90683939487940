import React from 'react';
import { DocumentsWrapper } from '../Investitors/Investors.style';
import { DocumentsContainer, EventsSection, NewContent,
  ReadNewsLink,
  Subtitle} from '../../components/DocumentsContent/DocumentContent.style';
import { useNews, useNewsCount } from '../../services/news.service';
import DocumentsSideBar from '../../components/DocumentsContent/DocumentsSideBar';
import { newsParsedDate } from '../../helpers/newsParseDate';
import {Helmet} from 'react-helmet-async';
import Pagination from 'rc-pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './PaginationStyle.css';
import nextIcon from'../../assets/images/active.png';
import { NextIcon, PaginationWrapper, PrevIcon } from './News.style';

const News = () => {

  const [search] = useSearchParams();

  const limit = 5;

  const page = search.get('page')||'1';

  const {data: news = {data: {data: []}}} = useNews(page, limit);
  const {data: allNews} = news;
  const {data: newsData} = allNews;

  if (status === 'loading') {
    return <div><p>Loading...</p></div>;
  }

  if (status === 'error') {
    return <div><p>Error</p></div>;
  }

  const {data} = useNewsCount();

  const navigate = useNavigate();
  const handleChange = (pageNr:string|number) => {
    navigate(`?page=${pageNr}`);
    search.set('page',pageNr as string);
  };

  const totalItems = data?.data.data[0].count.title;

  const nextIconConverter = () => {
    return <NextIcon src={nextIcon} alt="" />;
  };

  const prevIconConverter = () => {
    return <PrevIcon src={nextIcon} alt="" />;
  };

  return (
    <DocumentsWrapper>
      <Helmet>
        <title>EOLO Investor News | EOLO</title>
        <meta name="description" content="Discover all the news on the EOLO investor area and stay up to date
        with the latest company information. Find out more on the official website" />
      </Helmet>
      <DocumentsSideBar />
      <DocumentsContainer>
        <h4>News</h4>
        <EventsSection id="news">
          {newsData && newsData.map((newsItem: {
              published_date?:  string;
              title: string;
              description: string;
              subtitle?: string;
              link?: string;
              id: number
            }, index: any) => {
            return (
              <NewContent key={index}>
                <h5>{newsItem.title}</h5>
                <Subtitle>{newsItem.subtitle}</Subtitle>
                <p>{newsParsedDate(newsItem.published_date)}</p>
                <ReadNewsLink to={`/news/${newsItem.id}`}>Read news</ReadNewsLink>
              </NewContent>
            );
          })}

          <PaginationWrapper>

            <Pagination
              onChange={handleChange}
              current={parseInt(page)}
              total={totalItems}
              pageSize={limit}
              showLessItems
              showTitle={false}
              nextIcon={nextIconConverter}
              prevIcon={prevIconConverter}
            />
          </PaginationWrapper>
        </EventsSection>
      </DocumentsContainer>
    </DocumentsWrapper>
  );
};

export default News;
