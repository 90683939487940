import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const FooterContent = styled.div`
  padding-top: 40px;
  background-color: #f2f2f2;
  padding-bottom: 10px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;

export const ContentDetail = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 15px;
  `;

export const PrivacyPolicyContent = styled.div`
  padding: 0 15px;
  flex:1;
  overflow: auto;
`;
export const AreasLinks = styled.div`
  display: flex;
  flex-wrap: wrap;

`;

export const FooterImage = styled.img`
  width: 100%;
  padding: 10px 0 0;
`;

export const FooterSubtitle = styled.p`
  font-family: Halcyon-Medium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #193755;
  margin: 20px 0 0;
`;

export const FooterLink = styled(Link)`
  text-decoration: none;
  font-family: Halcyon-Medium;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  letter-spacing: normal;
  color: #193755;
  &::after{
    content: "|";
    padding: 0 7px;
  }
  &:last-child{
    ::after{
      content: "";
    }
  }
`;

export const CopyRights = styled.p`
  font-family: Halcyon-Regular;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #193755;
`;

export const PrivacyLinks = styled.div`
  margin-top: 30px;
`;

export const SocialFooter = styled.div`
margin-top: 30px;
color: #193755;
font-family: Halcyon-Medium;
p{
  margin: 0;
  padding: 0;
  line-height: 16px;
}
  a{
    display: inline-block;
    vertical-align: middle;
    img{
      width: 2rem;
    }
  }
`;

