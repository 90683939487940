import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SidebarStyle=styled.div`
  display: flex;
  flex: 0 0 20%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  background-color: #193755;
  padding-top: 25px;
  @media (max-width: 768px) {
    display:none;
  }
`;

export const SidebarLink = styled(Link) `
 cursor:pointer;
  color: #fff;
  background-color: #ff8a00;
  border: 1px solid transparent;
  padding: 7px 15px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  margin: 15px 0;
  text-decoration: none;
  min-width: 80px;
  font-weight: bold;
  &:hover {
    background-color: #ffd199;
  }
`;
