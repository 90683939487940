import {  ReactElement, FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getLocalAccessToken } from '../services/token.service';
import PrivateLayout from './PrivateLayout';

export interface PrivateRouteProps {
  children: ReactElement
}

export const PrivateRoute:FC<PrivateRouteProps> = ({children}) => {
  const token = getLocalAccessToken();
  const location = useLocation();

  if (token) {
    return (
      <PrivateLayout classNames="privateLayout">
        {children}
      </PrivateLayout>
    );
  }

  return <Navigate to="/login" state={{ from: location.pathname }} replace/>;
};
