import React from 'react';
import PrivateLayout from '../../components/PrivateLayout';
import Layout from '../../components/PublicLayout';
import Cookie_Policy from '../../components/TermsAndConditionsContent/Cookie_Policy';
import { PrivacySubtitle, PrivacyTitle } from '../../components/TermsAndConditionsContent/Privacy.style';
import { getLocalAccessToken } from '../../services/token.service';
import { FormsPage } from '../../shared-styled-components/Forms.style';

const CookiePolicy = () => {
  const token = getLocalAccessToken();

  return(
    <div className="privacyPolicy">
      {token ?
        <PrivateLayout classNames="layoutRow">

          <FormsPage>
            <PrivacyTitle>Cookie Policy – Investor relation website</PrivacyTitle>
            <PrivacySubtitle>Cookie policy pursuant to articles 13-14 of EU Regulation 2016/679
            (General Data Protection Regulation)</PrivacySubtitle>
            <Cookie_Policy />
          </FormsPage>
        </PrivateLayout>
        :
        <Layout classNames="layoutRow">
          <FormsPage>
            <PrivacyTitle>Cookie Policy – Investor relation website</PrivacyTitle>
            <PrivacySubtitle>Cookie policy pursuant to articles 13-14 of EU Regulation 2016/679
            (General Data Protection Regulation)</PrivacySubtitle>
            <Cookie_Policy />
          </FormsPage>
        </Layout>
      }
    </div>
  );
};

export default CookiePolicy;
