export interface loggedInUser {
  token:string,
  refresh_token:string
}

export const getLocalRefreshToken = () => {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')||'{}');

  return loggedInUser?.refresh_token;
};

export const  getLocalAccessToken=() => {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')||'{}');

  return loggedInUser?.access_token;
};

export const  updateLocalAccessToken=(token:string) => {
  const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser')||'{}');

  loggedInUser.access_token = token;
  localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
};

export const  getUser=() => {
  return JSON.parse(localStorage.getItem('loggedInUser')|| '{}');
};

export const  setUser=(loggedInUser:loggedInUser) => {

  localStorage.setItem('loggedInUser', JSON.stringify(loggedInUser));
};

export const  removeUser=() => {
  localStorage.removeItem('loggedInUser');
};
