import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Layout from '../../components/PublicLayout';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import * as Yup from 'yup';
import { login } from '../../services/auth.service';
import { ErrorMessage, FormBodyWrapper, FormCheckbox, FormGroup,
  FormLabel, FormsPage, FormTitle, FormWrapper, NavigationLink,
  PrimaryButton, PrivacyPolicy, TitleWrapper } from '../../shared-styled-components/Forms.style';
import { useMutation } from 'react-query';
import setCookie from '../../helpers/setCookie';
import getCookie from '../../helpers/getCookie';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { from } = location.state as any || { from: '/'};

  const rememberMeCookieName='remember-me';

  const initialValues = {
    email: getCookie(rememberMeCookieName),
    password: '',
    rememberMe: getCookie(rememberMeCookieName)!==''
  };

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required e-mail'),
    password: Yup.string().required('Required password'),
  });

  const loginMutation = useMutation((credentials:{email:string,password:string}) => {
    return login(credentials.email,credentials.password);
  },{
    onSuccess:() => {
      navigate(`${from}`);
    }
  });

  const handleSubmit=(values:any,actions:any) => {
    loginMutation.mutate(values);

    if(values.rememberMe){
      setCookie(rememberMeCookieName,values.email,7);
    }

  };

  return (
    <Layout classNames="layoutRow" title="Login - EOLO Investor Relations | EOLO"
      description="Enter your username and password and enter EOLO investor area. Are you a new user instead?
    Create a new account or request help from EOLO.">
      <FormsPage>
        <FormTitle>Welcome</FormTitle>
        <FormBodyWrapper>
          <FormWrapper>
            <TitleWrapper>
              <h4>Use a local account to log in</h4>
            </TitleWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validSchema}
              onSubmit={(values, actions) => handleSubmit(values,actions)}
            >
              {({ errors, touched }) => {

                return(
                  <Form>
                    <FormGroup>
                      <FormLabel htmlFor="email">E-mail </FormLabel>
                      <Field type="email" id="email" name="email" />
                      {(errors.email && touched.email) && (<ErrorMessage>{errors.email}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="password">Password </FormLabel>
                      <Field type="password" id="password" name="password" />
                      {(errors.password && touched.password) &&( <ErrorMessage>{errors.password}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox>
                        <Field type="checkbox" id="rememberMe" name="rememberMe"/>
                        <FormLabel htmlFor="rememberMe">Remember me</FormLabel>
                      </FormCheckbox>
                    </FormGroup>
                    {loginMutation.isError && <ErrorMessage>Email or password is not correct!</ErrorMessage>}
                    <PrimaryButton disabled={loginMutation.isLoading} type="submit" >Log in</PrimaryButton>
                  </Form>
                );
              }}
            </Formik>
            <FormGroup>
              <NavigationLink to="/forget-password" >Forget your Password</NavigationLink>
              <NavigationLink to="/register" >Register as a new user</NavigationLink>
            </FormGroup>
          </FormWrapper>
          <PrivacyPolicy>
            <TitleWrapper>
              <h4>Login instructions</h4>
            </TitleWrapper>
            <p>Please provide the information on the left to login</p>
          </PrivacyPolicy>
        </FormBodyWrapper>
      </FormsPage>

    </Layout>
  );
};

export default Login;

