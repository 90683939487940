import React from 'react';
import { useParams } from 'react-router';
import { DocumentItem, DocumentsContainer, NewsTitle, Subtitle }
  from '../../components/DocumentsContent/DocumentContent.style';
import { useNewsDetails } from '../../services/news.service';
import { DocumentsWrapper } from '../Investitors/Investors.style';
import DocumentsSideBar from '../../components/DocumentsContent/DocumentsSideBar';
import { newsParsedDate } from '../../helpers/newsParseDate';
import { Helmet } from 'react-helmet-async';

const NewsDetail = () => {

  const renderHTML = (rawHTML: string) => React.createElement('div', {dangerouslySetInnerHTML: {__html: rawHTML}});

  const param =useParams();

  if(!param.newsId){
    return <div>Not found</div>;
  }

  const {data} = useNewsDetails(param.newsId);

  return (
    <DocumentsWrapper>
      <Helmet>
        <title>{data?.data.data.title}</title>
        <meta name="description" content={data?.data.data.subtitle}/>
      </Helmet>
      <DocumentsSideBar />
      <DocumentsContainer>
        <DocumentItem >
          <NewsTitle>{data?.data.data.title}</NewsTitle>
          <Subtitle>{ data?.data.data.subtitle}</Subtitle>
          <p>{ newsParsedDate(data?.data.data.published_date)}</p>
          {renderHTML( data?.data.data.description)}
        </DocumentItem>
      </DocumentsContainer>
    </DocumentsWrapper>
  );
};

export default NewsDetail;
