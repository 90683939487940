/* eslint-disable max-len */
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const  DocumentsContainer= styled.div`
  margin: 15px 25px;
  flex: 3;
  @media screen and (max-width: 768px){
    margin: 15px;
  }
  h4{
    margin: 0;
    font-size: 42px;
    color: #193755;
  }
`;

export const  DocumentsContent= styled.div`
`;

export const  DetailTitle= styled.div`
  display: flex;
  border-bottom: 1px solid #193755;
  align-items: center;
  font-size: 16px;
  line-height: 23px;
  font-family: Halcyon-Regular;
  p:first-child{
      flex: 1;
      margin: 0;
      padding: 0;
      border-right: 1px solid #193755;
      padding-bottom: 5px;
    } 
  p:last-child{
      flex: 4;
      margin: 0;
      padding: 0;
      padding-bottom: 5px;
      padding-left: 10px;
    } 
`;

export const  Detailcontent= styled.div`
  display: flex;
  font-size: 18px;
  line-height: 23px;
  align-items: center;
  font-family: Halcyon-Regular;
  p{
      flex: 1;
    } 
  a{
      flex: 4;
      text-decoration: none;
      padding-left: 10px;
      color: #193755;
      &:hover{
        font-weight: 600;
      }
    } 
`;

export const  DetailDate= styled.div`
  font-size: 24px;
  color: #0086cc;
  font-weight: 400;
  margin-bottom: 5px;
  padding-top: 20px;
`;

export const  DocumentItem= styled.div`
  a{
    font-family: Halcyon-Medium;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    text-decoration: none;
    color: black;
    box-shadow: rgba(0, 160, 230, 0.1) 0px 8px 24px;
    max-width: 550px;
    width: 100%;
    img{
      padding-left: 15px;
    }
    span{
      padding: 0 15px;
      color:#193755;
    }
  }
`;

export const  BlockLinksWrapper= styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px){
    flex-direction: column;
    margin: 0 15px 0 0;
  }
`;

export const  BlocLinks= styled(Link)`
  background-color: #193755;
  border-radius: 10px;
  width: calc(50% - 30px);
  margin-bottom: 20px;
  color: #fff;
  font-size: 24px;    
  font-weight: 200;
  padding: 20px 10px 10px 10px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  min-height: 58px;
  align-items: end;
  @media screen and (max-width: 768px){
    width: 100%;
  }
`;

export const  EventsSection= styled.div`
  h4{
    padding-top: 30px;
  }
`;

export const  NewContent= styled.div`
  padding: 10px 0;
  border-top: 2px solid #c1c2c3;
  h5{
    color: #193755;
    margin: 0;
    padding-bottom: 10px;
    font-size: 18px;
  }
  div{
    color: #656b73;
    font-size: 16px;
    font-family: Halcyon-Regular;
    >a{
    text-decoration: none;
    color: #ff8a00;
    }
  }
  `;

export const ReadMore = styled(Link)`
    color: #000;
    text-decoration: none;
    font-weight: 700;
    width: 100%;
    display: block;
    text-align: end;
    margin-bottom: 15px;
 `;

export const ContactDetails = styled.p`
    padding-top: 30px;
    font-size: 18px;
    color: #656b73;
  a{
    /* text-decoration: none; */
    color: #193755;
  }  
`;

export const ReadNewsLink = styled(Link)`
    display: flex;
    justify-content: end;
    text-decoration: none;
    color: #193755;
    font-family: Halcyon-Medium;
    padding-bottom: 10px;
`;

export const ReadAllNewsBlock = styled.div`
   a{
     border-top: 2px solid #c1c2c3;
     font-weight: 600;
     font-size: 18px;
   }
`;

export const Subtitle = styled.h6`
    color: #193755;
    font-family: Halcyon-Regular;
    margin: 0;
    padding-bottom: 10px;
    font-size: 16px;
`;

export const NewsTitle = styled.h5`
    color: #193755;
    margin: 0;
    padding-bottom: 10px;
    font-size: 18px;
`;


export const SideBarList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 15px;
  flex: 1;
  list-style-type: none;
  padding: 0;
  margin-block-start: 0;
  @media screen and (max-width: 768px){
    margin-right: 15px;
    border-radius: 0;
    display: none;
  }
  a,p{
    padding: 0 15px;
    margin: 7px 0;
    font-family: Halcyon-Medium;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: #193755;
    display: inline;
    display: flex;
    align-items: center;
  }
  a:hover{
    font-weight: bold;
  }
`;

export const SideBarList2 = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  a{
    padding: 0;
  }
 
`;
export const SideBarItem = styled.li`
  margin-left: 15px;
`;


