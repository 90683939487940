import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Layout from '../../components/PublicLayout';
import { ErrorMessage, ForgetPasswordTitle, FormBodyWrapper, FormGroup, FormLabel,
  FormsPage, FormTitle, FormWrapper, PrimaryButton } from '../../shared-styled-components/Forms.style';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { forget_Password } from '../../services/auth.service';

const ForgetPassword = () => {

  const navigate = useNavigate();

  const loginMutation = useMutation((credentials:{email:string}) => {
    return forget_Password(credentials.email);
  },{
    onSuccess:() => {
      navigate('/registration-confirm');
    }
  });

  const handleSubmit=(values:any,actions:any) => {
    loginMutation.mutate(values);
  };

  const initialValues = {
    email: '',
  };

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required e-mail'),
  });

  return (
    <Layout classNames="layoutRow" title="Reset your Password | EOLO"
      description="Have you forgotten your password to access the EOLO investor area? Quickly reset it using the form">
      <FormsPage>
        <FormTitle>Forget your password</FormTitle>
        <ForgetPasswordTitle>
          <h4>Enter your e-mail</h4>
        </ForgetPasswordTitle>
        <FormBodyWrapper>
          <FormWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validSchema}
              onSubmit={(values, actions) => handleSubmit(values,actions)}
            >
              {({ errors, touched }) => {
                return(
                  <Form>
                    <FormGroup>
                      <FormLabel htmlFor="email">E-mail: </FormLabel>
                      <Field type="email" id="email" name="email" />
                      {(errors.email && touched.email) && (<ErrorMessage>{errors.email}</ErrorMessage>)}
                    </FormGroup>
                    <PrimaryButton type="submit" >Submit</PrimaryButton>
                  </Form>
                );
              }}
            </Formik>
          </FormWrapper>
        </FormBodyWrapper>
      </FormsPage>
    </Layout>
  );
};

export default ForgetPassword;
