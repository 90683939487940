import api from './api';
import { removeUser, setUser } from './token.service';

export const login=(email:string, password:string) => {
  return api
    .post('auth/login', {
      email,
      password
    })
    .then((response) => {
      if (response.data.data.access_token) {
        setUser(response.data.data);
      }

      return response.data.data;
    });
};

export const register=(
  email:string,
  name:string,
  surname:string,
  company:string,
  email_notifications:boolean
) => {
  return api
    .post('v1/register', {
      email,
      name,
      surname,
      company,
      email_notifications
    },{
      headers:{
        'x-api-key':'8171b46f-679f-4c8f-b402-92334be675d5'
      }
    });
};

export const logout=() => {
  removeUser();
};


export const confirm_Password=(
  password:string,
  confirmPassword:string,
  token: string
) => {
  return api
    .post('v1/confirm-password', {
      password,
      confirmPassword,
    },{
      headers:{
        'Token': token
      }
    });
};

export const forget_Password=(
  email:string,
) => {
  return api
    .post('v1/forget-password', {
      email
    });
};
