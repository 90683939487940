import React from 'react';
import { Field, Form, Formik } from 'formik';
import Layout from '../../components/PublicLayout';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { ErrorMessage, FieldRequiredText, FormBodyWrapper, FormCheckbox, FormGroup,
  FormLabel, FormsPage, FormTitle, FormWrapper, PrimaryButton,
  PrivacyPolicy, TitleWrapper } from '../../shared-styled-components/Forms.style';
import { register } from '../../services/auth.service';
import { useMutation } from 'react-query';

const Register = () => {
  const navigate = useNavigate();

  const initialValues = {
    email: '',
    name:'',
    surname:'',
    company:'',
    acceptDisclaimer:false,
    termsAndConditions:false,
    privacyPolicy:false,
    email_notifications:false,
  };

  const validSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required e-mail'),
    name: Yup.string().required('Required name'),
    surname: Yup.string().required('Required surname'),
    company: Yup.string().required('Required company'),
    acceptDisclaimer: Yup.boolean().oneOf([true], 'Required'),
    termsAndConditions: Yup.boolean().oneOf([true], 'Required'),
    privacyPolicy: Yup.boolean().oneOf([true], 'Required'),
    email_notifications: Yup.boolean().oneOf([true], 'Required'),
  });

  const registerMutation = useMutation(
    (credentials:{email:string,name:string,surname:string,company:string, email_notifications:boolean}) => {
      return register(
        credentials.email,
        credentials.name ,
        credentials.surname ,
        credentials.company,
        credentials.email_notifications,
      );
    },{
      onSuccess:() => {
        navigate('/registration-confirm');
      }
    });

  const handleSubmit = (values:any,actions:any) => {
    registerMutation.mutate(values);
  };

  return (
    <Layout classNames="layoutRow" title="Register on the EOLO Investor portal | EOLO"
      description="Register a new account and enter EOLO`s investor area. You will have
      the opportunity to view company reports, results and presentations">
      <FormsPage>
        <FormTitle> Register</FormTitle>
        <FormBodyWrapper>

          <FormWrapper>
            <TitleWrapper>
              <h4>Create a new account</h4>
            </TitleWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validSchema}
              onSubmit={(values, actions) => handleSubmit(values,actions)}
            >
              {({ errors, touched }) => {

                return(
                  <Form>
                    <FormGroup>
                      <FormLabel htmlFor="email">E-mail*</FormLabel>
                      <Field type="email" id="email" name="email" />
                      {(errors.email && touched.email) && (<ErrorMessage>{errors.email}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="name">Name*</FormLabel>
                      <Field type="text" id="name" name="name" />
                      {(errors.name && touched.name) &&
                      ( <ErrorMessage>{errors.name}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="surname">Surname*</FormLabel>
                      <Field type="text" id="surname" name="surname" />
                      {(errors.surname && touched.surname) &&
                      ( <ErrorMessage>{errors.surname}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="company">Company*</FormLabel>
                      <Field type="text" id="company" name="company" />
                      {(errors.company && touched.company) &&
                      ( <ErrorMessage>{errors.company}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox>
                        <Field type="checkbox" id="acceptDisclaimer" name="acceptDisclaimer"/>
                        <FormLabel htmlFor="acceptDisclaimer">Accept Disclaimer*</FormLabel>
                        {(errors.acceptDisclaimer && touched.acceptDisclaimer) &&
                      ( <ErrorMessage>{errors.acceptDisclaimer}</ErrorMessage>)}
                      </FormCheckbox>
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox>
                        <Field type="checkbox" id="termsAndConditions" name="termsAndConditions"/>
                        <FormLabel htmlFor="termsAndConditions">
                          I have read, understood and accept the
                          <a href="/terms-and-conditions"> Terms & Conditions</a>*</FormLabel>
                        {(errors.termsAndConditions && touched.termsAndConditions) &&
                      ( <ErrorMessage>{errors.termsAndConditions}</ErrorMessage>)}
                      </FormCheckbox>
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox>
                        <Field type="checkbox" id="privacyPolicy" name="privacyPolicy"/>
                        <FormLabel htmlFor="privacyPolicy">
                          I have read, understood and accept the
                          <a href="/privacy-policy"> Privacy Policy</a>*</FormLabel>
                        {(errors.privacyPolicy && touched.privacyPolicy) &&
                      ( <ErrorMessage>{errors.privacyPolicy}</ErrorMessage>)}
                      </FormCheckbox>
                    </FormGroup>
                    <FormGroup>
                      <FormCheckbox>
                        <Field type="checkbox" id="email_notifications" name="email_notifications"/>
                        <FormLabel htmlFor="email_notifications">I would like to receive newsletter</FormLabel>
                      </FormCheckbox>
                    </FormGroup>
                    <FieldRequiredText>* Field required</FieldRequiredText>
                    {registerMutation.isError &&
                    <ErrorMessage>There seems to have a problem, check the data and try again!</ErrorMessage>}
                    <PrimaryButton disabled={registerMutation.isLoading} type="submit">Register</PrimaryButton>
                  </Form>
                );
              }}
            </Formik>

          </FormWrapper>

          <PrivacyPolicy>
            <TitleWrapper>
              <h4>DISCLAIMER</h4>
            </TitleWrapper>
            <p>This area of the website is reserved to investors of Zoncolan Bidco S.p.A. (the "<b>Issuer</b>").
              The information contained in this reserved area of the website (the "<b>Information</b>") is confidential
              and does not constitute an offer to sell or a solicitation of an offer to buy any securities of
              the Issuer or any of its direct or indirect subsidiaries or members of its corporate group (together,
              the "<b>EOLO Group</b>").
            <br/>
            <br/>
              Any securities of the EOLO Group referred to in the Information may not be offered or sold in the United
              States absent registration unless pursuant to an applicable exemption from the registration requirements
              of the U.S.
              Securities Act of 1933 (the "<b>U.S. Securities Act</b>") and other applicable securities laws. The
              Information is not intended for distribution into or within the United States of America or to U.S.
              persons other than to "qualified institutional buyers" as defined under Rule 144A of the U.S.
              Securities Act.
            <br/>
            <br/>
              Any such securities may be offered or sold in the Member States of the European Economic Area only
              to "qualified investors" within the meaning of Article 2(1)(e) of Regulation (EU) 2017/1129.
              Securities may be offered in the United Kingdom only to persons who (i) have professional
              experience in matters relating to investments falling
              within Article 19(5) of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005
              (as amended the "<b>Financial Promotion Order</b>"); (ii) are persons falling within Article 49(2)(a) to
              (d) ("high net worth companies, unincorporated associations, etc.") of the Financial Promotion Order; or
              (iii) are persons to whom an invitation or inducement to engage in investment activity (within the
              meaning of section 21 of the Financial Services and Markets Act 2000) in connection with the issue
              or sale of any securities may otherwise lawfully be communicated or caused to be communicated.
            <br/>
            <br/>
              The Information may include forward-looking statements. All forward-looking statements involve risks
              and uncertainties, which could affect the actual results of the EOLO Group and could cause the EOLO
              Group's actual results to differ materially from those expressed in any forward-looking statements
              made by, or on behalf of, the EOLO Group.
            <br/>
            <br/>
              By clicking "Accept" below, you confirm that you have read and understood the foregoing disclaimer
              and that you are authorized to access the Information without being subject to any legal restriction
              and without any further action required by the EOLO Group.

            </p>
          </PrivacyPolicy>
        </FormBodyWrapper>
      </FormsPage>
    </Layout>
  );
};

export default Register;
