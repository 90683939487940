import {useQuery} from 'react-query';
import api from './api';

export const getAllEvents = () => {
  const published = 'published';

  return api.get(`items/events?filter={"status":{"_eq": "${published}"}}`);
};


export const useEvents = () => {
  return useQuery(['Events'], () => getAllEvents());
};
