import React, { ReactChild, useEffect, useState} from 'react';
import { Navigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { getLocalAccessToken } from '../services/token.service';
import { MobileMenu } from '../shared-styled-components/Forms.style';
import PublicFooter from './Footer/PublicFooter';
import Header from './Header/Header';
import { HamburgerIcon, HamburgerMenu } from './Header/Header.style';
import Sidebar from './Sidebar';
import iconX from '../assets/images/iconX.png';
// import CookiesBanner from './CookiesBanner';
import {Helmet} from 'react-helmet-async';

type LayoutProps = {
  classNames:string
  children:ReactChild
  title?:string
  description?:string
}

const Layout = ({classNames,children, title, description}:LayoutProps) => {


  const token = getLocalAccessToken();

  const location = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.key]);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  if(token){
    return (<Navigate to={'/documents'}/>);
  }

  return (
    <div className="main publicLayout">
      <Helmet>
        <title>{title || 'EOLO Investor Relations | EOLO'}</title>
        <meta name="description" content={description
          || `Everything EOLO investors need to know: view all important information on the company,  
          see our latest financial results, and download documents and reports`}/>
      </Helmet>
      <div id="mobile" className={`${hamburgerOpen ? 'navigation': ''}`}>
        <div id="sidebar">

          <HamburgerMenu onClick={toggleHamburger}>
            <HamburgerIcon alt="menu button"
              src={iconX}/>
          </HamburgerMenu>
          <MobileMenu>
            <Link to="/login" onClick={toggleHamburger}>Login</Link>
            <Link to="/register" onClick={toggleHamburger}>Register</Link>
          </MobileMenu>

        </div>
        <div className={`${hamburgerOpen ? 'eoloContainerScale' : ''} eoloContainer`}>

          <Header toggleHamburger={toggleHamburger} hamburgerOpen={hamburgerOpen}/>
          <div className={classNames}>
            {location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions'
            || location.pathname === '/cookie-policy'
              ? '' : <Sidebar />}
            {children}
          </div>
          <PublicFooter />
        </div>
      </div>
      {/* <CookiesBanner /> */}
    </div>
  );
};

export default Layout;
