import styled from 'styled-components';

export const NextIcon = styled.img`
  width: 20px;
  height: 20px;
  box-shadow: 0 0 12px 0 rgb(26 41 57 / 20%);
`;

export const PrevIcon = styled.img`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  box-shadow: 0 0 12px 0 rgb(26 41 57 / 20%);
`;

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
