import React from 'react';
import Layout from '../../components/PublicLayout';
import { HeroImages, HeroSubtitle, HeroText, HeroTitle } from './Home.style';

const Home = () => {

  return (
    <Layout classNames="layoutRow">
      <HeroImages>
        <HeroText>
          <HeroTitle>EOLO</HeroTitle>
          <HeroSubtitle>Investor relations</HeroSubtitle>
        </HeroText>
      </HeroImages>
    </Layout>
  );
};

export default Home;
