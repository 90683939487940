import React from 'react';
import { Link,LinkProps,useResolvedPath, useMatch } from 'react-router-dom';

const CustomLink = ({ children, to, ...props }: LinkProps) => {

  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <div className="sideBarList">
      <Link
        style={{ fontWeight: match ? 'bold' : 'normal' }}
        to={to}
        {...props}
      >
        {children}
      </Link>
    </div>
  );
};

export default CustomLink;
