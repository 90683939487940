import { useQuery } from 'react-query';
import api from './api';

export const getAllDocuments=() => {
  return api.get('items/document');
};

export const getDocumentsByType=(type:string) => {
  return api.get(`items/document?filter={ "type": { "_eq": "${type}" }}&sort=sort,-date_published`);
};


export const useDocuments=(type:string) => {
  return useQuery(['documents', type], () => getDocumentsByType(type));
};
