import styled from 'styled-components';
import homepageImage from '../../assets/images/homepage-2.jpg';

export const HeroImages = styled.div `
  background-image: url(${homepageImage});
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width:100%;
  min-height: 600px;
  @media screen and (max-width: 768px){
    min-height: 500px;
  }
`;

export const HeroText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  color: white;
  text-align:center;
  text-shadow: 2px 2px 10px #000000;
  @media screen and (max-width: 768px){
    text-shadow: none;
  }
`;

export const HeroTitle = styled.h1`
  font-size:50px;
  margin:0;
`;

export const HeroSubtitle = styled.h1`
  font-size:40px;
  margin:0;
`;
