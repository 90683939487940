import axios, {AxiosRequestConfig} from 'axios';
import {object} from 'yup/lib/locale';
import {getLocalAccessToken, getLocalRefreshToken, removeUser, setUser} from './token.service';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json'
  },
});

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getLocalAccessToken();

    if (token) {
      return {
        ...config,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };
    }

    return config;

  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/login' && err.response) {
      // Access Token was expired
      if ((err.response.status === 401 || err.response.status === 403) && !originalConfig._retry) {
        originalConfig._retry = true;
        delete originalConfig.headers['Authorization'];
        delete instance.defaults.headers.common['Authorization'];
        const refreshToken = getLocalRefreshToken();

        removeUser();

        try {
          if (refreshToken) {
            const rs = await instance.post('/auth/refresh', {
              refresh_token: refreshToken,
            });

            const {data} = rs;

            if (data.data) {
              setUser(data.data);
            }

          }

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
