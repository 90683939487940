import React, { useState } from 'react';
import './App.css';
import Routes from './helpers/router';
import {QueryClientProvider, QueryClient} from 'react-query';
import { HelmetProvider } from 'react-helmet-async';

const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>

      <QueryClientProvider client={queryClient}>
        <Routes />
      </QueryClientProvider>
    </HelmetProvider>

  );
}

export default App;

