import React from 'react';
import { FormBodyWrapper } from '../../shared-styled-components/Forms.style';
import { PrivacyWrapper, Table, TableItems, TableTitle } from './Privacy.style';
import {Helmet} from 'react-helmet-async';

const Cookie_Policy = () => {
  return (
    <FormBodyWrapper>
      <Helmet>
        <title>Cookie Policy | EOLO</title>
        <meta name="description" content="We use cookies and similar technologies, including those of
        authorized third parties, to offer you a better experience of using and browsing the site" />
      </Helmet>
      <PrivacyWrapper>
        <div>
          <h4>Foreword</h4>
          <p>EOLO invites the user/visitor to carefully read this Cookie Policy, as it contains important
            information on the methods of management of the website for the protection of personal data in
            full compliance with Regulation 2016/679/EU (General Data Protection Regulation), the Italia
            Privacy Code (Legislative Decree 196/2003, the c. d. Privacy Code) as amended by Legislative
            Decree 101/2018, the General Measure of the Italian Authority for the Protection of Personal
            Data of 8 May 2014 on cookies and other applicable legislation (hereinafter Regulation 2016/679/EU,
            Privacy Code, General Measure of 8 May 2014 and other applicable legislation are collectively
            referred to as "Applicable Legislation").
            This Cookie Policy is intended to be provided only for the website www.investors.eolo.it while it does not
            apply to other websites that may be consulted through external links.
            EOLO informs that, in accordance with the legislative provisions of the Applicable Legislation,
            the processing of personal data will be based on the principles of lawfulness, correctness,
            transparency, limitation of purpose and storage, data minimization, accuracy, integrity and confidentiality.
          </p>
          <h4>
          Data Controller and Data Protection Officer
          </h4>
          <p>The Data Controller is EOLO SpA with registered office in Via Gran San Bernardo,
            12 - 21052 - Busto Arsizio (VA), which can be contacted at: privacy@eolo.it.
            EOLO has appointed a Data Protection Officer (DPO), available at: dpo@eolo.it.
          </p>
          <h4>Processed data</h4>
          <p>a. Browsing data <br/>
          The computer systems and software procedures used to operate the site www.investors.eolo.it
          acquire, during their normal operation, some data whose transmission is implicit in the communication
          protocols of the Internet. This is information that by their very nature could, through processing
          and association with other data, allow users to be identified.
          This category of data includes browsing data such as, for example, the internet protocol (IP) address
          used to connect your computer to the internet; information about the computer and connection such as browser
          type and version; information about the device (e.g. device ID), the time of the request, the method
          used to submit the request to the server, the size of the file obtained in response, the
          numerical code indicating the status of the response given by the server (successful, error, etc.);
           other parameters relating to the user's operating system
          and computer environment.These data are used only to obtain anonymous statistical information on the use of
          the site, to check its correct functioning, to allow the proper provision of the various features requested
          by the user. This data will be kept for the time technically necessary for these purposes, without
          prejudice to further storage for possible  investigation of computer crimes against the Site,
          for security reasons or to respond to specific regulatory obligations.
          </p>
          <p>b. Data provided voluntarily by the user <br/>
          Data provided voluntarily by the user are the data that are communicated by the user in connection
          with the registration to the Website.Unlike the data collected automatically, which are
          necessary to carry out computer and telematic protocols,
          the user is free to provide the personal data requested from time to time, which will be
          processed only to allow the use of the services involved and to manage the requests and
          for the time indicated in the specific information available at this
          address: www.investors.eolo.it/cookie-policy
          </p>
          <p>c. Cookies and related technologies <br/>
          The website www.investors.eolo.it uses cookies to make its services simple and efficient
          for users who view the pages in accordance with applicable regulations.
          </p>
          <h4>What are cookies</h4>
          <p>A cookie is a small piece of data (text file) that a website, when visited by a user,
            asks your browser to store on your device to remember your information, such as your
            preferred language or login information. These cookies are set by us and referred to
            as first party cookies.</p>
          <h4>Cookies on the Investor Relation Website of EOLO S.pA.</h4>
          <h4>Technical cookies</h4>
          <p>This type of cookie can be distinguished in permanent or session cookies, and are intended
              to ensure the transmission of communication between you and the website. These cookies are
              essential for the proper functioning of the site and cannot be disabled in our systems;
              for this reason they are used without the consent of the person concerned.
              They are usually set only in response to actions performed by you that constitute a request
              for services, such as setting privacy preferences, accessing areas dedicated to registered
              users, registering on the site, using the shopping cart or filling out forms. These cookies
              do not store personal information
          </p>
          <Table>
            <tr>
              <TableTitle>Cookie</TableTitle>
              <TableTitle>Origin</TableTitle>
            </tr>
            <tr>
              <TableItems>remeber-me </TableItems>
              <TableItems>1st Party</TableItems>
            </tr>
          </Table>
          <h4>Recipients</h4>
          <p>The data processing will be carried out only by subjects previously authorized, appointed by
            EOLO SpA, such as employees of EOLO SpA or external companies that collaborate with EOLO and
             that have signed with EOLO a specific agreement on the processing of personal data.
          </p>
          <h4>Rights of the interested parties</h4>
          <p>Within the limits of the Applicable Law, the user has the right to ask EOLO, at any time,
            for access to his/her Personal Data, to rectify or cancel the same or to oppose their
            processing, to request the portability of the data, the limitation of the processing
            as well as to obtain the data concerning him/her in a structured, commonly used and
            machine-readable format. Requests should be sent by e-mail to: privacy@eolo.it.
            Pursuant to the Applicable Regulations, the user has in any case the right to lodge a complaint
            with the competent Control Authority (Italian Guarantor for the Protection of Personal Data) if
            he/she considers that the processing of his/her Personal Data is contrary to the regulations in force.
          </p>
          <h4>Modifications</h4>
          <p>EOLO reserves the right to modify or simply update the content, in part or completely,
            also due to variations in Applicable Law. You are therefore invited to visit this section
            regularly in order to be aware of the most recent and updated version of this document
            and so that the user is always up to date on the data collected and the use that EOLO makes of it.</p>
        </div>

      </PrivacyWrapper>

    </FormBodyWrapper>
  );
};

export default Cookie_Policy;
