import React from 'react';
import PrivateLayout from '../../components/PrivateLayout';
import Layout from '../../components/PublicLayout';
import { PrivacyTitle } from '../../components/TermsAndConditionsContent/Privacy.style';
import Terms_Conditions from '../../components/TermsAndConditionsContent/Terms_Conditions';
import { getLocalAccessToken } from '../../services/token.service';
import { FormsPage } from '../../shared-styled-components/Forms.style';

const TermsAndConditions = () => {
  const token = getLocalAccessToken();

  return(
    <div className="privacyPolicy">
      {token ?
        <PrivateLayout classNames="layoutRow">
          <FormsPage>
            <PrivacyTitle>Terms and Conditions</PrivacyTitle>
            <Terms_Conditions />
          </FormsPage>
        </PrivateLayout>
        :
        <Layout classNames="layoutRow">
          <FormsPage>
            <PrivacyTitle>Terms and Conditions</PrivacyTitle>
            <Terms_Conditions />
          </FormsPage>
        </Layout>
      }
    </div>
  );
};

export default TermsAndConditions;
