import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.div`
  background-color: #F1F1F1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  @media screen and (max-width: 768px){
    flex-wrap: wrap;
  }
`;

export const NavLogo=styled(Link)`
  color: #193755;
  text-decoration: none;
  max-width: 200px;
  padding: 0 15px;
  img{
    width: 100%;
  }  
  @media screen and (max-width: 768px){
   padding: 0 10px;
  }
`;

export const Navlink=styled(Link)`
  cursor: pointer;
    color: #fff;
    background-color: #ff8a00;
    border: 1px solid transparent;
    padding: 7px 15px;
    font-size: 18px;
    line-height: 1.5;
    border-radius: 0.25rem;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    margin: 15px;
    font-weight: bold;
    text-decoration: none;
    font-family:Halcyon-Regular;
  &:hover {
    background-color: #ffd199; 
  }
`;

export const HamburgerIcon=styled.img`
  display: none;
  color: #fff;
  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    width: 26px;
  }  
  @media screen and (max-width: 425px) {
    width: 26px;
  }  
`;

export const NavMenu=styled.div<{ menuOpen?: boolean }>`
  display: flex;
  align-items: center;
  button{
    margin-right: 15px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
    &:hover{
      background-color: #ffd199;
    }
  }
  @media screen and (max-width: 768px){
    width: 100%;
    flex-direction: column;
    display: ${(props) => props.menuOpen ? 'flex' : 'none'};
    background-image: linear-gradient(90deg, #193755 0%, #19375555 70%);
    align-items: flex-start;
    a{
      padding: 15px;
      font-size: 18px;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
`;

export const HamburgerMenu=styled.div`
  display: none;
  @media screen and (max-width: 768px){
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px;
  }
`;

export const DropDownContainer = styled.div`
  width: auto;
  margin: auto;  
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  position: relative;
`;

export const DropDownHeader = styled.div`
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    cursor: pointer;
    width: 28px;
  }
`;

export const DropDownListContainer = styled.div`
  position:absolute;
  top: 35px;
  right: 15px;
  width: 170px;
  `;

export const DropDownList = styled.ul`
  border-radius: 4px;
  font-family:Halcyon-Regular;
  margin: 0;
  padding:0;
  box-shadow: rgb(0 160 230 / 13%) 0px 8px 24px;
  background: white;
  color: #3faffa;
  `;

export const ListItem = styled.li`
  list-style: none;
  font-family:Halcyon-Regular;
  width: 100%;
  padding: 10px 0;
  color: #193755;
  &:last-child {
    color: #ff8a00 !important;
    border-top: 1px solid #0000000f;
  }
`;

export const DropdownLink = styled(Link)`  
    text-decoration: none;
    color: inherit;
    padding:0 15px;
    font-family:Halcyon-Medium;
`;

export const DropdownButton = styled.span`  
    text-decoration: none;
    color: inherit;
    padding:0 15px;
    cursor: pointer;
    font-family:Halcyon-Medium;
`;
