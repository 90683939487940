import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import logo from '../../assets/images/mainlogo.png';
import { logout } from '../../services/auth.service';
import { HamburgerIcon, Nav, NavMenu, NavLogo, HamburgerMenu} from './Header.style';
import toggleMenu from '../../assets/images/toggle_button.svg';
import DropDownContent from './DropDownContent';


type LayoutProps = {
  toggleHamburger():any
  hamburgerOpen:boolean
}

const PrivateHeader = ({toggleHamburger, hamburgerOpen}:LayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout =() => {
    logout();
    navigate('/');
  };

  return (
    <Nav>
      <NavLogo to="/homepage" >
        <img src={logo}
          alt="logo" />
      </NavLogo>
      <>
        <HamburgerMenu onClick={toggleHamburger}>
          <HamburgerIcon alt="menu button"
            src={toggleMenu}/>
        </HamburgerMenu>
        <NavMenu >
          <DropDownContent handleLogout={handleLogout}/>
        </NavMenu>
      </>

    </Nav>
  );
};

export default PrivateHeader;
