import React from 'react';
import { Routes,Route, Navigate } from 'react-router-dom';
import ForgetPassword from '../pages/ForgetPassword/ForgetPassword';
import Login from '../pages/Login/Login';
import {PrivateRoute}  from '../components/PrivateRoute';
import Register from '../pages/Register/Register';
import NotFoundPage from '../pages/404';
import Investors from '../pages/Investitors';
import Home from '../pages/Home';
import RegistrationConfirm from '../pages/RegistrationConfirm';
import HomePage from '../components/DocumentsContent/HomePage';
import ConfirmPassword from '../pages/ConfirmPassword';
import TermsAndConditions from '../pages/TermsAndConditions';
import CookiePolicy from '../pages/CookiePolicy';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import News from '../pages/News';
import Profile from '../pages/Profile';
import NewsDetails from '../pages/NewsDetails';
import NewsLetterPage from '../pages/NewsLetter';
import { getLocalAccessToken } from '../services/token.service';
import Layout from '../components/PublicLayout';

const AppRoutes = () => {

  const token = getLocalAccessToken();

  return (
    <Routes>
      <Route path={'/'} element={<Home />} />
      <Route path={'/login'} element={<Login />} />
      <Route path={'/register'} element={<Register />} />
      <Route path={'/forget-password'} element={<ForgetPassword />} />
      <Route path={'/registration-confirm'} element={<RegistrationConfirm />} />
      <Route path={'/confirm-password'} element={<ConfirmPassword />} />
      <Route path={'/terms-and-conditions'} element={<TermsAndConditions />} />
      <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
      <Route path={'/cookie-policy'} element={<CookiePolicy />} />
      <Route path={'/404'} element={<NotFoundPage />} />
      <Route path={'/newsletter'} element={
        token ?
          <PrivateRoute>
            <NewsLetterPage />
          </PrivateRoute>
          :
          <Layout classNames="layoutRow">
            <NewsLetterPage />
          </Layout>

      } />
      <Route path={'/homepage'} element={
        <PrivateRoute>
          <HomePage />
        </PrivateRoute>
      } />
      <Route path={'/news'} element={
        <PrivateRoute>
          <News />
        </PrivateRoute>
      } />
      <Route path={'/profile'} element={
        <PrivateRoute>
          <Profile />
        </PrivateRoute>
      } />
      <Route path={'/documents'} element={<Navigate to={'/homepage'}/>}  />
      <Route path={'/documents/:docType'} element={
        <PrivateRoute>
          <Investors />
        </PrivateRoute>
      } />
      <Route path={'/news/:newsId'} element={
        <PrivateRoute>
          <NewsDetails />
        </PrivateRoute>
      } />
    </Routes>
  );
};

export default AppRoutes;
