import styled from 'styled-components';

export const  DocumentsWrapper= styled.div`
  display: flex;
  justify-content: flex-start;
  max-width: 1500px;
  margin: 25px auto;
  flex: 1;
  width: 100%;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;

export const  SideBar= styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  min-width: 270px;
  align-items: flex-start;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 15px;
  flex: 1;
  flex-grow: 0;
  
  @media screen and (max-width: 768px){
    margin-right: 15px;
    border-radius: 0;
    display: none;
  }
  a{
    padding: 0 15px;
    margin: 7px 0;
    font-family: Halcyon-Medium;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: #193755;
    display: inline;
    display: flex;
    align-items: center;
    &:hover{
      font-weight: bold;
    }
  }

`;
