import { useQuery } from 'react-query';
import api from './api';

export const getUser=() => {
  return api.get('users/me');
};


export const useUser=() => {
  return useQuery(['user'], () => getUser());
};
