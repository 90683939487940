import React from 'react';
import { SideBar } from '../../pages/Investitors/Investors.style';
import CustomLink from '../CustomLinks';
import arrow from '../../assets/images/arrow-short.svg';
import { SideBarItem, SideBarList, SideBarList2 } from './DocumentContent.style';

const DocumentsSideBar = () => {
  return (
    <SideBar>
      <CustomLink to="/homepage"> Homepage</CustomLink>
      <SideBarList>
        <SideBarItem>
          <p>Documents</p>
          <SideBarList2>
            <SideBarItem>
              <CustomLink to="/documents/annual_report"><img src={arrow} alt="short arrow"/> Annual Reports</CustomLink>
            </SideBarItem>
            <SideBarItem>
              <CustomLink to="/documents/quarterly_reporting">
                <img src={arrow} alt="short arrow"/> Quarterly Reports</CustomLink>
            </SideBarItem>
            <SideBarItem>
              <CustomLink to="/documents/presentations"><img src={arrow} alt="short arrow"/>Presentations</CustomLink>
            </SideBarItem>
            <SideBarItem>
              <CustomLink to="/documents/ratings"><img src={arrow} alt="short arrow"/>Ratings</CustomLink>
            </SideBarItem>
          </SideBarList2>
        </SideBarItem>
        <SideBarItem>
          <CustomLink to="/news">News</CustomLink>
        </SideBarItem>
        <SideBarItem>
          <CustomLink to="/documents/contacts">Contacts</CustomLink>
        </SideBarItem>
      </SideBarList>
    </SideBar>
  );
};

export default DocumentsSideBar;
