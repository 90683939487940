import React from 'react';
import { FormBodyWrapper } from '../../shared-styled-components/Forms.style';
import { PrivacyWrapper } from './Privacy.style';
import {Helmet} from 'react-helmet-async';

const Terms_Conditions = () => {
  return (
    <FormBodyWrapper>
      <Helmet>
        <title>Terms and Conditions | EOLO</title>
        <meta name="description" content="Consult on this page the general
        terms and conditions for all EOLO Investor services." />
      </Helmet>
      <PrivacyWrapper>
        <div>
          <h4>1. General Information</h4>
          <p>1.1 EOLO S.p.A. has its registered office in Busto Arsizio (VA) - via
            Gran San Bernardo 12 - enrolled in the Varese companies register, Fiscal
            Code, VAT number and companies register no. 02487230126 (hereinafter referred to as "EOLO").</p>
          <h4>2. Background and relation with other rules</h4>
          <p>2.1 These general terms and conditions (hereinafter "General Conditions")
            govern the methods of use of the Investor Relations Website, owned by EOLO,
            (hereinafter the "Website"). The Website may be used by natural persons aged
            18 years or over (hereinafter the "User(s)") who intend to use the Website
            in order to make use of the functions offered, such as for example the
            consultation of the contents (hereinafter the "Service").
          </p>
          <p>2.2. By accessing the Website, and/or by any other use of the Website and
            its contents, the User accepts and undertakes to comply with these Conditions
            without modification, as well as to comply with all applicable legal provisions.
            Where further conditions, rules, guidelines, codes of conduct or instructions
            for the User are contained in the Website or in any part of it, the User also
            undertakes to comply with them.
          </p>
          <p>2.3 In order to allow the User to access and use the content on the Website,
            the User will be asked to register or provide certain personal data and/or
            information. The User undertakes to provide complete, updated and truthful
            data and information, and to communicate to EOLO any variation in the data
            and information provided.</p>
          <p>2.4 From the first use of the Website and each subsequent access, the User
            acknowledges and accepts as legally binding these General Conditions. In the
            event that the User is unable or unwilling to accept any clause of these General
            Conditions, he must refrain from using the Website and delete his account from the Website</p>
          <h4>3. Industrial and intellectual property</h4>
          <p>3.1. All the trademarks, logos and distinctive signs that appear on the Website are registered
            and used trademarks belonging to EOLO with its registered office in Busto Arsizio (VA) -
            via Gran San Bernardo 12 - enrolled in the Company Register of Varese, Tax Code, VAT number
            and Company Register No. 02487230126, and/or to third parties who have authorised EOLO to
            use the same. All the industrial and intellectual property rights relating to the Website,
            the EOLO trademark or their contents (hereinafter, also the "Intellectual Properties of EOLO")
            are and remain the exclusive property of EOLO. It is expressly forbidden for the User to
            copy, modify, decode, duplicate, distribute EOLO's Intellectual Properties, as well as
            to create works derived from or based on EOLO's Intellectual Properties as well as
            derived from or based on any other trademark, commercial name, logo, ideogram, or other
            distinctive sign owned by EOLO or by its licensors.</p>
          <h4>4. Conditions of use of the Website and Account</h4>
          <p>4.1 The User is responsible for the secrecy of his authentication credentials
            (password, etc.) and, in any case, of all data required to access and/or use
            the Website. The User is also responsible for all activities that take place
            through the use of his Account. The User is obliged to immediately notify EOLO
            of any unauthorised use of her/his Account. In case of communication to third
            parties and, in any case, of illicit use of the authentication credentials, EOLO
            may proceed at any time to suspend, interrupt or cancel the registration of the
            Account and, in any case, inhibit the User's access to the Website. Any unauthorized
            use must be immediately communicated to EOLO by email to the following address legale@eolo.it.</p>
          <p>4.2 When accessing the Website for the first time, the User is obliged to confirm:<br/>
            i) the acceptance of these Terms & Conditions of Use;<br/>
            ii) the acknowledgement of the Privacy Policy. </p>
          <h4>5. Website functionalities</h4>
          <p>With regard to the functionalities made available to the User, the Website allows, by way of example,
            access to the functionalities available through the use of the credentials of your account, such as
            consultation of the documents and contents present on the Website, and receipt of news.</p>
          <h4>7. Prohibited uses</h4>
          <p>7.1. Users are expressly forbidden, purely by way of example and without limitation, to:<br/>
            - the use of the Website and its Contents for purposes and/or purposes other than those mentioned above and,
            in particular, for uses and/or purposes of a commercial nature;<br/>
            - any use of the Website and its Contents that is not explicitly permitted on the basis of these Conditions
            and/or and thus, by way of example, the modification, communication, distribution, transmission, copying,
            duplication, publication of the Contents present and/or obtainable through the Website including personal
            data, information, graphics, images as well as all other Contents of the Website prepared and/or transmitted
            directly by EOLO, by affiliated companies or their suppliers of products and/or services;<br/>
            - the use of the Website or its contents on other Websites, servers or other computer environments;<br/>
            - the use of the Website or its Contents in violation of any applicable legislation or, in any case, any
            use capable of causing damage to the Website itself (including the interruption or limitation of the Website
            or its contents and services);<br/>
            - the collection or attempted collection of information and/or personal data on third parties through the
            Website. </p>
          <h4>8. Modification, Interruption and Suspension of the Website </h4>
          <p>8.1 EOLO reserves the right to update, modify or discontinue the Website and/or the functionalities
            made available with the same, at any time, with or without prior notice, in a manner reasonable for
            the User and guaranteeing the maintenance of the rights acquired by each User, based on the correct
            use of the Website.</p>
          <p>8.2 EOLO has the right to temporarily suspend or interrupt all or part of the Website and/or its
            functionalities for technical reasons, without any obligation to inform the Users individually.</p>
          <p>8.3 EOLO reserves the right to suspend or deactivate the Website for individual Users in case of <br/>
            1. improper use or use for illicit purposes of the Website (including, by way of example but not
            limited to, the use of credentials belonging to another User, the deletion and/or modification
            of any content of the Website);<br/>
              2. non-compliance with the General Conditions, and any additional conditions accepted by
            the User from time to time;<br/>
              3. fraudulent or illegal actions carried out through the Website;<br/>
              4. actions aimed at disrupting the proper functioning of the Website.</p>
          <p>8.4 The User hereby waives its right to claim against EOLO for any suspension or
            interruption of the Website due to network malfunctions or force majeure</p>
          <h4>9. Force Majeure</h4>
          <p>9.1 EOLO shall not be liable and shall not be obliged to pay any compensation to the
              User in the event of failure to fulfil one of its obligations under these General
              Terms and Conditions due to or in connection with events of force majeure such as,
              but not limited to, riots, social unrest, pandemics, telephone or computer breakdowns,
              serious damage to security and the operation of the internet.</p>
          <h4>Liability</h4>
          <p>10.1 EOLO shall not be liable for any damages suffered or caused by the User as a result of the
            use of the Website.</p>
          <p>10.2 In particular, except in the case of wilful misconduct or gross negligence, EOLO is
            exonerated from any responsibility towards the User or third parties in the case of<br/>
            - damages occurred due to unavailability or malfunctioning of the Website;<br/>
            - damages of any kind caused to the Users, or to the consequences that may arise from their personal,
            professional or commercial activities<br/>
            - inability to access or use the Website and/or its functionalities for any reason whatsoever,
            especially in the event of maintenance operations, updates or technical improvements.<br/>
            10.3 EOLO accepts no responsibility for any damage resulting from improper use of the Website by Users.<br/>
            10.4 Notwithstanding the foregoing, EOLO shall not be liable in any case towards the User or third
            parties for<br/>
            - the temporary or permanent suspension of the Website<br/>
            - the contents and the Websites of third parties to which reference may be made through the Website.
          </p>
          <h4>11. Applicable Law and Jurisdiction</h4>
          <p>11.1 These General Terms and Conditions and the relations regulated by them are governed by
            Italian law. </p>
          <p>11.2 Any dispute that may arise with reference to the existence, interpretation,
            effectiveness, validity, execution, non-fulfilment and application of these General Conditions,
            as well as the individual relations regulated by the same, shall be referred exclusively to the
            Court of Busto Arsizio (VA).</p>
          <h4>12. Updating of General Conditions</h4>
          <p>12.1 EOLO reserves the right to update the present General Conditions at any time and at its
            complete discretion. Should EOLO make significant modifications to the present General Conditions,
            EOLO undertakes to inform the User by reasonable means.</p>
          <p>12.2 In the event that the User does not wish to accept the amended General Terms and Conditions
            following the updates made to these General Terms and Conditions, he must immediately cease
            using the Website and delete his account from the Website.
          </p>
          <h4>13. Final provisions</h4>
          <p>13.1 The invalidity and/or ineffectiveness of one or part of the clauses of these General
            Conditions does not entail the invalidity and/or ineffectiveness of all the other clauses,
            which will continue to remain in force and binding for the part not affected by said
            invalidity and/or ineffectiveness.</p>
          <p>
            13.2 Failure to exercise, or partial or delayed exercise of, any of the rights, powers or
            faculties provided for in these General Terms and Conditions shall not in any way
            constitute a waiver thereof, nor shall it constitute a waiver of the right to demand
            proper performance of the obligations assumed.
          </p>
        </div>

      </PrivacyWrapper>

    </FormBodyWrapper>
  );
};

export default Terms_Conditions;
