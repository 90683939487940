import { useQuery } from 'react-query';
import api from './api';

export const setNewsLetter=(
  email_notifications:boolean
) => {
  return api
    .post('v1/update-newsletter', {
      email_notifications
    });
};

export const getNewsLetter=() => {
  return api.get('v1/update-newsletter');
};

export const useNewsLetter = () => {
  return useQuery(['NewsLetter'], () => getNewsLetter());
};
