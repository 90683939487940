import React from 'react';
import {useDocuments} from '../../services/documents.service';
import {useParams} from 'react-router';
import pdfLogo from '../../assets/images/pdf.svg';
import {
  ContactDetails, DetailDate, DocumentItem, DocumentsContainer,
  DocumentsContent
} from './DocumentContent.style';
import {getLocalAccessToken} from '../../services/token.service';
import {Helmet} from 'react-helmet-async';

const DocumentContent = () => {

  const params = useParams();
  const docType = params.docType || '';
  const {data: documents, status} = useDocuments(docType);
  const token = getLocalAccessToken();

  if (status === 'loading') {
    return <div><p>Loading...</p></div>;
  }

  if (status === 'error') {
    return <div><p>Error</p></div>;
  }

  interface documentItem {
    id: string
    name: string
    attachment: string
    date_created: string
    date_published: string
  }

  const pageMeta:{ [key: string]: {
    title:string,
    description:string
  } }  = {
    'contacts':{
      title:'Investor relations contacts | EOLO',
      description:`Here you can find EOLO's contacts. We are at your disposal to 
      provide you with administrative and technical assistance.`
    },
    'annual_report':{
      title:'Annual financial report | EOLO',
      description:`Here you can access, browse and download EOLO's financial 
      statements and annual reports. Find out more on the official Investors portal`
    },
    'quarterly_reporting':{
      title:'Quarterly financial reports | EOLO',
      description:`Here you can access, browse and download EOLO's quarterly 
      reports. Find out more on the official Investors portal`
    },
    'presentations':{
      title:'Conferences and Presentations | EOLO',
      description:`Here you can browse and download  EOLO's conferences 
      and presentations. Find out more on the official Investors portal`
    },
    'ratings':{
      title:'Credit Ratings | EOLO Investor',
      description:`Everything EOLO investors need to know about EOLO's credit 
      ratings shared from the main rating agencies. Find out more on the official website`
    }
  };



  const pageMetaKey:string = params.docType||'default';

  return (
    <DocumentsContainer>
      <Helmet>
        <title>{ pageMeta[pageMetaKey].title}</title>
        <meta name="description" content={pageMeta[pageMetaKey].description}/>
      </Helmet>
      {params.docType === 'contacts' ?
        <h4>Contacts</h4>
        : params.docType === 'annual_report'
          ? <h4>Annual Reports</h4>
          : params.docType === 'news'
            ? <h4>News</h4>
            : params.docType === 'quarterly_reporting'
              ? <h4>Quarterly Reports</h4>
              : params.docType === 'presentations'
                ? <h4>Presentations</h4>
                : params.docType === 'ratings'
                  ? <h4>Ratings</h4>
                  : <h4>Choose a function</h4>
      }
      {params.docType === 'contacts' ?
        <DocumentsContent>
          <ContactDetails>E-mail: <a href="mailto:investor.relations@eolo.it">
            investor.relations@eolo.it</a></ContactDetails>
        </DocumentsContent>
        :
        <DocumentsContent>
          {documents?.data?.data && Array.isArray(documents?.data?.data)
            && documents?.data?.data.map((document: documentItem, index: number) => {

              const splittedYear = document.date_published ? document.date_published.split('-')
                : document.date_created.split('-');
              const [year] = splittedYear;

              return (
                <DocumentItem key={index}>
                  <DetailDate>{year}</DetailDate>
                  <a href={`${process.env.REACT_APP_API_URL}/assets/${document.attachment}?access_token=${token}`}
                    target="_blank" download="Your File.pdf">
                    <img src={pdfLogo} width="30" alt="pdf logo"/>
                    <span>{document.name}</span>
                  </a>

                </DocumentItem>
              );
            })}
        </DocumentsContent>
      }
    </DocumentsContainer>
  );
};

export default DocumentContent;
