import React from 'react';
import {SidebarLink, SidebarStyle} from './Sidebar.style';

const Sidebar = () => {

  return (
    <SidebarStyle>
      <SidebarLink to="/login" > Log in</SidebarLink>
      <SidebarLink to="/register" >Register</SidebarLink>
    </SidebarStyle>
  );
};

export default Sidebar;
