import React, { useState } from 'react';
import { DocumentsContainer } from '../../components/DocumentsContent/DocumentContent.style';
import DocumentsSideBar from '../../components/DocumentsContent/DocumentsSideBar';
import Sidebar from '../../components/Sidebar';
import { setNewsLetter } from '../../services/newsletter.service';
import { getLocalAccessToken } from '../../services/token.service';
import { PrimaryButton } from '../../shared-styled-components/Forms.style';
import { DocumentsWrapper } from '../Investitors/Investors.style';

const NewsLetterPage = () => {
  const [unsubscribeMessage, setUnsubscribeMessage] = useState('');

  const handleSubmit = () => {
    setNewsLetter(false)
      .then(() => {
        setUnsubscribeMessage(`You have been successfully unsubscribed from 
        our newsletter, you will no longer receive our e-mails`);
      })
      .catch(() => {
        setUnsubscribeMessage('Something went wrong');
      });

  };

  const token = getLocalAccessToken();

  return (

    <DocumentsWrapper>
      {
        token && <DocumentsSideBar />

      }
      <DocumentsContainer>
        <p>You can unsubscribe from our newsletter by clicking on the button below.</p>
        <PrimaryButton type="submit"
          onClick={() => handleSubmit()}>Unsubscribe now</PrimaryButton>

        <p>{unsubscribeMessage}</p>

      </DocumentsContainer>
    </DocumentsWrapper>

  );
};

export default NewsLetterPage;
