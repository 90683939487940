import React from 'react';
import { HamburgerIcon, Nav, NavLogo, HamburgerMenu, NavMenu, Navlink} from './Header.style';
import logo from '../../assets/images/mainlogo.png';
import toggleMenu from '../../assets/images/toggle_button.svg';
import { useLocation } from 'react-router';

type LayoutProps = {
  toggleHamburger():any
  hamburgerOpen:boolean
}

const Header = ({toggleHamburger}:LayoutProps) => {

  const location = useLocation();

  return (
    <Nav>
      <NavLogo to="/" >
        <img src={logo}
          alt="logo" />
      </NavLogo>

      <HamburgerMenu onClick={toggleHamburger}>
        <HamburgerIcon alt="menu button"
          src={toggleMenu}/>
      </HamburgerMenu>
      {(location.pathname === '/privacy-policy' || location.pathname === '/terms-and-conditions'
      || location.pathname === '/cookie-policy')
        && <NavMenu>
          <Navlink to="/register">
                Register
          </Navlink>
          <Navlink to="/login">
                Log in
          </Navlink>
        </NavMenu> }


    </Nav>
  );
};

export default Header;
