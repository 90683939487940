import React from 'react';
import Layout from '../../components/PublicLayout';
import {FormBodyWrapper, FormBodyWrapperRegisterConfirm,
  FormsPage, FormsPageRegisterconfirm,
  FormTitle,
  PrivacyPolicy,
  TitleWrapper} from '../../shared-styled-components/Forms.style';
import { EmailWrapper } from './RegistrationConfirm.style';
import gmail from '../../assets/images/icon-gmail.svg';
import outlook from '../../assets/images/icon-microsoft-outlook.svg';

const RegistrationConfirm = () => {


  return (
    <Layout classNames="layoutRow">
      <FormsPage>
        <FormTitle>Confirm</FormTitle>
        <FormBodyWrapper>
          <PrivacyPolicy>

            <TitleWrapper>
              <h4>An email will be sent to your address!</h4>
            </TitleWrapper>
            <EmailWrapper>
              <a href="https://mail.google.com" target="_blank">
                <img src={gmail} alt="gmail icon"/> Open Gmail
              </a>
              <a href="https://outlook.live.com" target="_blank">
                <img src={outlook} alt="outlook icon"/> Open Outlook
              </a>
            </EmailWrapper>
            <div>
              <p>Didn't get an email?</p>
              <p>Check your spam folder</p>
            </div>
          </PrivacyPolicy>

        </FormBodyWrapper>
      </FormsPage>
    </Layout>
  );
};

export default RegistrationConfirm;
