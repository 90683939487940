import React from 'react';
import DocumentContent from '../../components/DocumentsContent/DocumentContent';
import { DocumentsWrapper, SideBar } from './Investors.style';
import DocumentsSideBar from '../../components/DocumentsContent/DocumentsSideBar';

const Investors = () => {

  return (
    <DocumentsWrapper>
      <DocumentsSideBar />
      <DocumentContent />
    </DocumentsWrapper>
  );
};

export default Investors;
