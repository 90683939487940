import React from 'react';
import {DocumentsWrapper, SideBar} from '../../pages/Investitors/Investors.style';
import {
  BlockLinksWrapper, BlocLinks, DocumentsContainer, DocumentsContent,
  NewContent, EventsSection, ReadNewsLink, ReadAllNewsBlock, Subtitle
} from './DocumentContent.style';
import rightIcon from '../../assets/images/freccia-sostenibilità.svg';
import {useEvents} from '../../services/events.service';
import {Helmet} from 'react-helmet-async';
import { useLimitNews } from '../../services/news.service';
import DocumentsSideBar from './DocumentsSideBar';
import { newsParsedDate } from '../../helpers/newsParseDate';


const renderHTML = (rawHTML: string) => React.createElement('div', {dangerouslySetInnerHTML: {__html: rawHTML}});
const HomePage = () => {

  const {data: events = {data: {data: []}}} = useEvents();
  const {data: allEvent} = events;
  const {data: eventsData} = allEvent;
  const {data: news = {data: {data: []}}} = useLimitNews();
  const {data: allNews} = news;
  const {data: newsData} = allNews;


  const parsedDate = (date?:string) => {
    if(date){
      return date.replace('T', ' ');
    }

    return null;
  };

  return (
    <DocumentsWrapper>
      <Helmet>
        <title>Results, reports and documents | EOLO</title>
        <meta name="description" content="Here you can browse and download EOLO's financial
        documents: financial statements, result, reports and presentations " />
      </Helmet>
      <DocumentsSideBar />
      <DocumentsContainer>
        <h4>Documents</h4>
        <DocumentsContent>
          <BlockLinksWrapper>
            <BlocLinks to="/documents/annual_report">Annual Reports <img src={rightIcon}/></BlocLinks>
            <BlocLinks to="/documents/quarterly_reporting">Quarterly Reports <img src={rightIcon}/></BlocLinks>
            <BlocLinks to="/documents/presentations">Presentations <img src={rightIcon}/></BlocLinks>
            <BlocLinks to="/documents/ratings">Ratings <img src={rightIcon}/></BlocLinks>
          </BlockLinksWrapper>
          <EventsSection id="events">
            <h4>Upcoming Events</h4>
            {eventsData && eventsData.map((eventsItem: {
              date?:  string;
              title: string;
              description: string;
              link?: string;
            }, index: any) => {
              const date=  parsedDate(eventsItem.date);

              return (
                <NewContent key={index}>
                  <h5>{date} {date && 'CET -'} {eventsItem.title}</h5>
                  <div>{renderHTML(eventsItem.description)}
                    <a href={`http://${eventsItem.link}`} target="_blank"> link</a></div>
                </NewContent>
              );
            })}
          </EventsSection>
          <EventsSection id="news">
            <h4>News</h4>
            {newsData && newsData.map((newsItem: {
              published_date?:  string;
              title: string;
              description: string;
              subtitle?: string;
              link?: string;
              id: number
            }, index: any) => {
              const date=  newsParsedDate(newsItem.published_date);

              return (
                <NewContent key={index}>
                  <h5>{newsItem.title}</h5>
                  <Subtitle>{newsItem.subtitle}</Subtitle>
                  <h5>{date} {date && 'CET'}</h5>
                  {/* {renderHTML(newsItem.description)} */}
                  <ReadNewsLink to={`/news/${newsItem.id}`}>Read news</ReadNewsLink>
                </NewContent>
              );
            })}

            <ReadAllNewsBlock>
              <ReadNewsLink to="/news">Read all news</ReadNewsLink>
            </ReadAllNewsBlock>
          </EventsSection>
        </DocumentsContent>
      </DocumentsContainer>
    </DocumentsWrapper>
  );
};

export default HomePage;
