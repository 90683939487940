import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FormsPage = styled.div `
  display:flex;
  flex-direction:column;
  width: 100%;
  margin: 0 15px;
  @media screen and (max-width: 768px){
    margin: 0px;
  }
`;

export const FormsPageRegisterconfirm = styled.div `
  display:flex;
  flex-direction:column;
  width: 100%;
  margin: 0 15px;
  justify-content: center;
  @media screen and (max-width: 768px){
    margin: 0px;
  }
`;

export const FormBodyWrapper = styled.div `
  display:flex;
  width: 100%;
  margin-bottom: 20px;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;

export const FormBodyWrapperRegisterConfirm = styled.div `
  display:flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;

export const FormTitle = styled.h1 `
  font-weight: 500 !important;
  line-height:48px;
  font-size:40px;
  padding: 0 25px;
  color: #193755;
  font-family: Halcyon-Medium;
  @media screen and (max-width: 768px){
    padding: 0 15px;
  }
`;

export const FormWrapper = styled.div `
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  margin: 0 25px;
  @media screen and (max-width: 768px){
    margin: 0 15px;
    max-width: 100%;
  }
`;

export const PrimaryButton = styled.button `
  cursor:pointer;
  color: #fff;
  background-color: #ff8a00;
  border: 1px solid transparent;
  padding: 7px 15px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  margin: 15px 0;
  font-weight: bold;
  font-family:Halcyon-Regular;
`;

export const FormCheckbox=styled.label`
   margin:15px 0;
`;

export const FormGroup=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #656b73;
  >input {
    display: block;
    font-family: Halcyon-Regular;
    width: 98%;
    padding: 7px 0 7px 15px;
    margin-top:15px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #656b73;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    :disabled{
      background-color: #f2f2f2;
    }
    @media screen and (max-width: 768px){
      padding: 7px 0 7px 10px;
      width: 98%;
    }
  }
`;

export const FormLabel=styled.label`
  margin-top:10px;
  font-size: 16px;
  a{
    color: #193755;
    /* text-decoration: none; */
  }
`;

export const NavigationLink = styled(Link) `
  margin: 7px 0;
  color: black;
  /* text-decoration:none; */
  font-size:16px;
  color: #656b73;
`;

export const PrivacyPolicy = styled.div `
  padding: 0 25px;
  color: #656b73;
  @media screen and (max-width: 768px){
      padding: 0px 15px;
    }
`;

export const TitleWrapper = styled.div `
  border-bottom:1px solid rgba(0,0,0,.1);
  color: #193755;
  font-family: Halcyon-Medium;
`;

export const ErrorMessage = styled.div `
  color:red;
`;

export const ForgetPasswordTitle = styled.div `
  margin: 0 25px;
  border-bottom:1px solid rgba(0,0,0,.1);
  font-family: Halcyon-Medium;
  h4{
    color: #193755;
  }
  @media screen and (max-width: 768px){
      margin: 0 15px;
    }
`;
export const FieldRequiredText = styled.div `
    color: #656b73;
`;

export const ProfileLink = styled(Link) `
    width: 100%;
    justify-content: flex-start;
    border-top: 1px solid white;
    padding: 16px 0 0px !important;
    margin-left: 15px !important;
    margin-bottom: 7px !important;
    margin-top: 15px !important;
    img{
      margin: 0 !important;
      width: 20px;
      padding-right: 10px;  
    }
`;

export const MobileMenu = styled.div `
 display: flex;
 flex-direction: column;
 a{
    padding: 0 15px;
    margin: 7px 0;
    font-family: Halcyon-Medium;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: white;
    display: inline;
    display: flex;
    align-items: center;
    &:hover{
      font-weight: bold;
    }
 }
`;


