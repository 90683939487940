import React, { useRef, useState } from 'react';
import { DropdownButton, DropDownContainer, DropDownHeader, DropdownLink, DropDownList,
  DropDownListContainer, ListItem } from './Header.style';
import user from '../../assets/images/user.png';
import { useOnClickOutside } from '../../helpers/useOnClickOutside';

const DropDownContent = ({handleLogout}:any) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = () => {
    setIsOpen(false);
  };

  const reference = useRef<HTMLDivElement>(null);

  useOnClickOutside(reference,onOptionClicked);

  return (
    <DropDownContainer ref={reference}>
      <DropDownHeader onClick={toggling}>
        <img src={user}/>
      </DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            <ListItem >
              <DropdownLink to={'/profile'} onClick={() => onOptionClicked()}>Profile</DropdownLink>
            </ListItem>
            <ListItem >
              <DropdownButton onClick={() => {
                handleLogout();
              }}> Log out</DropdownButton>
            </ListItem>

          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default DropDownContent;
