import styled from 'styled-components';

export const PrivacyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  max-width: 1140px;
  color: #656b73;
  div{
    padding: 0 15px;
    p{
      padding: 0;
      margin: 0;
    }
    h4{
      color: #193755;
      margin-bottom: 5px;
    }
  }
`;


export const Table = styled.table`
  font-family: Halcyon-Regular, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: auto;
  tr:nth-child(odd) {
    background-color: #dddddd;
  }
`;

export const TableTitle = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const TableItems = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;

export const PrivacyTitle = styled.h2`
  text-align: center;
  font-family: Halcyon-Regular;
  font-size: 42px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #193755;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  margin: 0;
`;

export const PrivacySubtitle = styled.h4`
  text-align: center;
  font-family: Halcyon-Regular;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #193755;
  width: 100%;
  margin: 0;
`;
