import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Layout from '../../components/PublicLayout';
import {Formik,Form,Field} from 'formik';
import * as Yup from 'yup';
import { confirm_Password } from '../../services/auth.service';
import { ErrorMessage, FormBodyWrapper, FormGroup,
  FormLabel, FormsPage, FormTitle, FormWrapper,
  PrimaryButton, PrivacyPolicy, TitleWrapper } from '../../shared-styled-components/Forms.style';
import { useMutation } from 'react-query';

const ConfirmPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    // @ts-ignore
    setToken(queryParams.get('token'));
  }, []);
  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  const validSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/^(?=.*[0-9])/,'Must Contain One Number')
      .matches(/^(?=.*[a-z])/,'Must Contain One Lowercase')
      .matches(/^(?=.*[A-Z])/,'Must Contain One Uppercase')
      .matches(/^((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,'Must Contain One Special Case Character')
      .required('Required password'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required confirm password')
  });

  const confirmPasswordMutation = useMutation((credentials:{confirmPassword:string,password:string, token: string}) => {
    // @ts-ignore
    return confirm_Password(credentials.confirmPassword,credentials.password, token);
  },{
    onSuccess:() => {
      navigate('/login');
    }
  });

  const handleSubmit=(values:any,actions:any) => {
    confirmPasswordMutation.mutate(values);
  };

  return (
    <Layout classNames="layoutRow">
      <FormsPage>
        <FormTitle>Confirm Password</FormTitle>
        <FormBodyWrapper>
          <FormWrapper>
            <TitleWrapper>
              <h4>Enter password to continue</h4>
            </TitleWrapper>
            <Formik
              initialValues={initialValues}
              validationSchema={validSchema}
              onSubmit={(values, actions) => handleSubmit(values,actions)}
            >
              {({ errors, touched }) => {
                return(
                  <Form>

                    <FormGroup>
                      <FormLabel htmlFor="password">Password: </FormLabel>
                      <Field type="password" id="password" name="password" />
                      {(errors.password && touched.password) &&( <ErrorMessage>{errors.password}</ErrorMessage>)}
                    </FormGroup>
                    <FormGroup>
                      <FormLabel htmlFor="confirmPassword">Confirm Password: </FormLabel>
                      <Field type="password" id="confirmPassword" name="confirmPassword" />
                      {(errors.confirmPassword && touched.confirmPassword) &&
                      (<ErrorMessage>{errors.confirmPassword}</ErrorMessage>)}
                    </FormGroup>
                    {confirmPasswordMutation.isError &&
                    <ErrorMessage>Confirm Password or password are not Equal!</ErrorMessage>}
                    <PrimaryButton disabled={confirmPasswordMutation.isLoading} type="submit" >Confirm</PrimaryButton>
                  </Form>
                );
              }}
            </Formik>
          </FormWrapper>
          <PrivacyPolicy>
            <TitleWrapper>
              <h4>Confirm Password instructions</h4>
            </TitleWrapper>
            <p>Please provide the information on the left to confirm your password</p>
          </PrivacyPolicy>
        </FormBodyWrapper>
      </FormsPage>

    </Layout>
  );
};

export default ConfirmPassword;
