import React from 'react';
import { AreasLinks, ContentDetail, CopyRights, FooterContainer, FooterContent, FooterImage, FooterLink, FooterSubtitle,
  PrivacyLinks, PrivacyPolicyContent, SocialFooter} from './Footer.style';
import logo from '../../assets/images/logo-eolo-blu.svg';
import footerImage from '../../assets/images/footer-image.png';
import ic_linkedin from '../../assets/images/ic_linkedin.svg';

const Footer = () => {
  return (
    <FooterContent>
      <FooterContainer>
        <ContentDetail>
          <img src={logo} alt="eolo footer logo"/>
          <FooterImage src={footerImage} alt="eolo footer logo"/>
          <CopyRights>© 2022 EOLO SpA - P. IVA 02487230126</CopyRights>
          <CopyRights>Founded in 1999, EOLO SpA is a national telecommunications operator,
            Leader in Ultra-Broadband Field For Residential and Business Market.</CopyRights>
        </ContentDetail>
        <PrivacyPolicyContent>
          <FooterSubtitle>Areas</FooterSubtitle>
          <AreasLinks>
            <FooterLink to="/homepage">Homepage</FooterLink>
            <FooterLink to="/documents/annual_report">Annual Reports</FooterLink>
            <FooterLink to="/documents/quarterly_reporting">Quarterly Reports</FooterLink>
            <FooterLink to="/documents/presentations">Presentations</FooterLink>
            <FooterLink to="/documents/ratings">Ratings</FooterLink>
            <FooterLink to="/news">News</FooterLink>
            <FooterLink to="/documents/contacts">Contacts</FooterLink>
            <FooterLink to="/profile">Profile</FooterLink>
          </AreasLinks>
          <PrivacyLinks>
            <FooterSubtitle>Legal info</FooterSubtitle>
            <FooterLink to="/terms-and-conditions">
              Terms & conditions</FooterLink>
            <FooterLink to="/privacy-policy">
              Privacy Policy</FooterLink>
            <FooterLink to="/cookie-policy">
              Cookie Policy</FooterLink>
          </PrivacyLinks>
          <SocialFooter>
            <p>Follow EOLO Institutional News: &nbsp;
              <a href="https://www.linkedin.com/company/eolo/" target="_blank">
                <img src={ic_linkedin}
                  alt="Linkedin"/>
              </a>
            </p>
          </SocialFooter>
        </PrivacyPolicyContent>

      </FooterContainer>
    </FooterContent>
  );
};

export default Footer;
